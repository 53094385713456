import React from "react"

import { Heading, RichText, Wrapper } from "components"

const IntroSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {data.title && (
        <Wrapper id={id} pt="calc(32px + 48px + 128px)">
          {data.title && <Heading size={900}>{data.title}</Heading>}
          {data.subtitle && (
            <Heading size={600} mt={["layout.2", "layout.5"]} fontWeight={400}>
              {data.subtitle}
            </Heading>
          )}
          {data.description && (
            <RichText
              content={{
                html: data.description,
              }}
              size={500}
              mt={["layout.2", null, null, "layout.3"]}
            />
          )}
        </Wrapper>
      )}
    </>
  )
}

export default IntroSection
