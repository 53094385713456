import React from "react"
import css from "@styled-system/css"

// Project components
import { Bin, Box, Button, Flex, Wrapper } from "components"

const BinsSection = ({ activeFilter, setActiveFilter, data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {data.bins && data.services && (
        <Wrapper id={id}>
          <Box overflow="hidden">
            <Flex
              flexWrap="wrap"
              m="-2px"
              css={{
                "> *": {
                  padding: "2px",
                },
              }}
            >
              {/* Set appearance primary on active item. All set to ghost when no filtering */}
              {data.services.map((service, index) => (
                <Box key={"serviceButton" + index}>
                  <Button
                    intent="secondary"
                    appearance={
                      activeFilter === service.uid ? "primary" : "ghost"
                    }
                    height={40}
                    onClick={() => {
                      if (activeFilter === service.uid) {
                        setActiveFilter("")
                      } else {
                        setActiveFilter(service.uid)
                      }
                    }}
                  >
                    {service.data.name && service.data.name.text}
                  </Button>
                </Box>
              ))}
            </Flex>
          </Box>
          <Box mt={["layout.4", "layout.6"]}>
            {data.bins && (
              <Flex
                flexWrap="wrap"
                mx={-16}
                my={-32}
                css={css({
                  "> *": {
                    width: ["50%", "33.333%"],
                    px: 16,
                    py: 32,
                  },
                })}
              >
                {data.bins.map((bin, index) => (
                  <Bin {...bin} key={"bin" + index} />
                ))}
              </Flex>
            )}
          </Box>
        </Wrapper>
      )}
    </>
  )
}

export default BinsSection
