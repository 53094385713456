import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

import { Divider, NextPage, SEO } from "components"

// Page sections
import IntroSection from "./_introSection"
import BinsSection from "./_binsSection"

function BinOptionsPage() {
  const staticData = useStaticQuery(graphql`
    {
      prismicBinOptions {
        data {
          seo_title {
            text
          }
          seo_description {
            text
          }
          seo_image {
            url
            alt
          }
          page_title {
            text
          }
          page_subtitle {
            text
          }
          page_description {
            text
          }
          bins {
            bin {
              uid
              document {
                ... on PrismicBin {
                  data {
                    image {
                      fluid(maxWidth: 600) {
                        ...GatsbyPrismicImageFluid
                      }
                      alt
                    }
                    name {
                      text
                    }
                    description {
                      text
                    }
                    height
                    width
                    depth
                    great_for
                    service_types {
                      service_type {
                        uid
                        document {
                          ... on PrismicService {
                            uid
                            data {
                              name {
                                text
                              }
                              color
                              bin_image {
                                fluid(maxWidth: 64) {
                                  ...GatsbyPrismicImageFluid
                                }
                                alt
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          next_page_image {
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          next_page_title {
            text
          }
          next_page_button_text
          next_page_button_link {
            slug
          }
        }
      }
      allPrismicService(sort: { fields: data___name___text, order: ASC }) {
        nodes {
          uid
          data {
            name {
              text
            }
          }
        }
      }
    }
  `)

  const previewData =
    typeof window !== "undefined" && window.__PRISMIC_PREVIEW_DATA__
  const finalData = mergePrismicPreviewData({ staticData, previewData })

  const [activeFilter, setActiveFilter] = useState("")

  return (
    <>
      <SEO
        title={
          finalData.prismicBinOptions.data.seo_title &&
          finalData.prismicBinOptions.data.seo_title.text
        }
        description={
          finalData.prismicBinOptions.data.seo_description &&
          finalData.prismicBinOptions.data.seo_description.text
        }
        image={
          finalData.prismicBinOptions.data.seo_image &&
          finalData.prismicBinOptions.data.seo_image.url
        }
        imageAlt={
          finalData.prismicBinOptions.data.seo_image &&
          finalData.prismicBinOptions.data.seo_image.alt
        }
      />

      {/* ============ INTRO ============ */}
      <IntroSection
        id="bins-intro"
        data={{
          title:
            finalData.prismicBinOptions.data.page_title &&
            finalData.prismicBinOptions.data.page_title.text,
          subtitle:
            finalData.prismicBinOptions.data.page_subtitle &&
            finalData.prismicBinOptions.data.page_subtitle.text,
          description:
            finalData.prismicBinOptions.data.page_description &&
            finalData.prismicBinOptions.data.page_description.text,
        }}
      />

      {/* ============ BINS ============ */}
      {finalData.prismicBinOptions && finalData.prismicBinOptions.data.bins && (
        <BinsSection
          id="bins-bins"
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          data={{
            services: finalData.allPrismicService.nodes,
            bins: finalData.prismicBinOptions.data.bins
              .filter(({ bin }) => {
                let found = false
                bin.document &&
                  bin.document.data &&
                  bin.document.data.service_types &&
                  bin.document.data.service_types.forEach(type => {
                    if (
                      type.service_type &&
                      type.service_type.uid === activeFilter
                    ) {
                      found = true
                    }
                  })
                if ((activeFilter === "" && bin.document) || found) {
                  return true
                } else {
                  return false
                }
              })
              .map(({ bin }) => {
                return {
                  image:
                    bin.document.data.image &&
                    bin.document.data.image.fluid &&
                    bin.document.data.image,
                  name: bin.document.data.name && bin.document.data.name.text,
                  description:
                    bin.document.data.description &&
                    bin.document.data.description.text,
                  greatFor: bin.document.data.great_for,
                  dimensions: {
                    width: bin.document.data.width,
                    depth: bin.document.data.depth,
                    height: bin.document.data.height,
                  },
                  serviceTypes: bin.document.data.service_types,
                }
              }),
          }}
        />
      )}
      {finalData.prismicBinOptions.data.next_page_title &&
        finalData.prismicBinOptions.data.next_page_title.text && (
          <>
            {/* ============ DIVIDER ============ */}
            <Divider id="bins-divider" />

            {/* ============ NEXT PAGE ============ */}
            <NextPage
              id="bins-nextpage"
              image={finalData.prismicBinOptions.data.next_page_image}
              title={
                finalData.prismicBinOptions.data.next_page_title &&
                finalData.prismicBinOptions.data.next_page_title.text
              }
              buttonLabel={
                finalData.prismicBinOptions.data.next_page_button_text
              }
              buttonTo={
                "/" +
                finalData.prismicBinOptions.data.next_page_button_link.slug +
                "/"
              }
            />
          </>
        )}
    </>
  )
}

export default BinOptionsPage
